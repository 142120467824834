import axios from 'axios'
// import Cookies from 'js-cookie'
import { Message } from 'element-ui'

// 是否显示重新登录
export let isRelogin = { show: false };

const _axios = axios.create({
    // baseURL:'/api',
    // baseURL: 'https://vrtraining-test.takeda.com.cn/testApi/', //测试
    baseURL:'https://vrtraining.molion.tech',//生产
    // baseURL:'https://vrtraining-t.molion.tech',//测试

    timeout: 60000, // 请求超时时间
    header: {
        'content-type': 'application/x-www-form-urlencoded',
    },
})
// 请求拦截
_axios.interceptors.request.use(
    (config) => {

       
        return config
    },
    (error) => {
        console.log('request11111111');
        return Promise.reject(error)
    }
)
// 响应拦截
_axios.interceptors.response.use(
    (res) => {
        // console.log(res)
        if (res.request.responseType == 'blob') {
            return res
        }
        if (res.data.code == 200) {
            // 成功处理
            return res.data
        } else {
            // 失败处理
            // 提示错误信息
            Message.error(res.data.msg)
            // 让接口调用执行.catch,中止.then的执行
            return Promise.reject(res)
        }
    },
    (error) => {
        console.log(error);
        return Promise.reject(error)
    }
)
export default _axios