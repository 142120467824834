import _axios from "../utils/request";

//控制温度
export const getVptUserInfoAPI = (data) => {
    return _axios({
        url: '/user/getVptUserInfo',
        method: 'get',
        params:data
    })
}
export const demoLogin = (data) => {
    return _axios({
        url: '/user/login ',
        method: 'post',
        data
    })
}

//控制温度
export const getProfile = (data) => {
    return _axios({
        url: '/profile',
        method: 'get',
        params:data
    })
}

//控制温度
export const getUserNickname = (params) => {
    return _axios({
        url: '/user/getUserNickname',
        method: 'get',
        params
    })
}