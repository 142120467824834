<template>
    <div class="body">
     
     <div class="OvertimeUp"  :class="isMobile?'OvertimeUp-iPhone':''">
      <div class="OvertimeUp-tips" :class="isMobile?'OvertimeUp-tips-iPhone':''">提示</div>
      <div class="OvertimeUp-text" v-if="isMobile">{{tips}}</div>
      <div class="OvertimeUp-text" v-if="isMobile&&tips!='您无权限访问此系统'">{{ TipsText2 }}</div>
      <div class="OvertimeUp-text-PC" v-if="!isMobile">{{tips}}</div>
      <div class="OvertimeUp-text-PC" v-if="!isMobile&&tips!='您无权限访问此系统'">{{ TipsText2 }}</div>
      <div class="OvertimeUp-buttom" @click="knowClick" :class="isMobile?'OvertimeUp-buttom-iPhone':''">知道了</div>
    </div>
    </div>
  </template>
  
  <script>
  import {demoLogin} from "../api/login"
  import { mapState} from "vuex";
  export default {
    props: {
        tips: {
      type: String,
      default: "",
    },

  },
    data() {
      return {
        TipsText1 : '未检测到邮箱账号',
              TipsText2 : '请重新登录',
        OvertimeShow:true
      }
    },
    computed:{
    ...mapState({
    
    isMobile: (state) => state.isMobile,
  
  }),
   },
    mounted() {
        console.log('isMobile',this.isMobile);
//      if(this.tips=='您无权限访问此系统'){
// this.TipsText2=''
//      }
    },
    methods:{
        knowClick(){
            console.log('关闭');
            this.$emit('loginTips-click'); // 触发自定义事件
        }
    }
  
}
  </script>
  
  <style lang="scss" scoped>
  .body {

 



  .OvertimeUp {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 40vw;
        // height: 40vh;
        z-index: 9999;
        background: #FFFFFF;
        border-radius: 40px;
        padding: 4vh 5vw;
        box-sizing: border-box;

        .OvertimeUp-tips {
            width: 100%;
            height: 5vh;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 34px;
            font-weight: 600;
            color: #000;
            margin-bottom: 3vh;
        }
        .OvertimeUp-tips-iPhone{
            font-size: px2rem(34);
            margin-bottom: 2vh;
        }

        .OvertimeUp-text {
            text-align: center;
            font-size:px2rem(28);
            font-weight: 500;
            color: #000;
            margin: 1vh 0;
       
        }
        .OvertimeUp-text-PC{
            text-align: center;
            font-size: 28px;
            font-weight: 500;
            color: #000;
            margin: 2vh 0;
        }

        .OvertimeUp-buttom {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 162px;
            height: 60px;
            border-radius: 50px;
            border: solid 1px #ED1C24;
            background: linear-gradient(90deg, #F78C85 0%, #E1242A 100%);
            font-size: 20px;
            color: #fff;
            margin: 0 auto;
            margin-top: 5vh;
            cursor: pointer;
        }
        .OvertimeUp-buttom-iPhone{
            font-size: px2rem(20);
            width: px2rem(162);
            height: px2rem(60);
            margin-top: 3vh;
        }
    }
  

  

  
  }
  </style>