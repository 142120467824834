<template>
  <div class="index">
<!-- <login v-if="loginShow" @login-click="loginTap"/> -->
<LarkSR v-show="LarkSRShow" @LarkSR-click="LarkSRClick" @Loading-click="LoadingClick"/>
<loginLoading v-show="loginShow" :Overtime="Overtime"/>
<loginTips v-show="Overtime" @loginTips-click="loginTipsClick" :tips="tips"/>
<!-- <router-view></router-view> -->
  </div>
</template>

<script>
// import login from "./components/LoginStateNetwork.vue"
import LarkSR from "./components/LarkSR/LarkSR.vue"
import loginLoading from "./components/loginLoading.vue"
import loginTips from "./components/loginTips.vue"
export default {
  components: {
    // login,
    LarkSR,
    loginLoading,
    loginTips
  },
  data(){
    return{
      loginShow:true,
      LarkSRShow:false,
      Overtime:false,
      tips:"",
      newWindow:"",
    }
  },
  mounted(){
    function getAllCookies() {  
    var cookies = {};  
    if (document.cookie && document.cookie !== '') {  
        var splits = document.cookie.split(';');  
        for (var i = 0; i < splits.length; i++) {  
            var cookie = splits[i].split('=');  
            var cookieName = cookie.shift().trim(); // 获取cookie名称  
            var cookieValue = cookie.join('='); // 获取cookie值（如果存在多个=，如包含转义字符）  
            cookies[cookieName] = decodeURIComponent(cookieValue); // 解码可能存在的特殊字符  
        }  
    }  
    return cookies;  
}  


  
// 使用函数  
var allCookies = getAllCookies();  
console.log('allCookies',allCookies);
  },
  methods:{
    loginTap(){
      this.loginShow=false
      this.LarkSRShow=true
    },
    // 关闭loding
    LarkSRClick(){
      this.loginShow=false
      this.LarkSRShow=true
    
    },
    // 打开登录错误提示
    LoadingClick(tips){
        this.Overtime=true
        this.tips=tips
    },
    loginTipsClick(){
    this.Overtime=false
    // if( this.tips=='您无权限访问此系统'){
// console.log('您无权限访问此系统');

// window.location.href="https://www.google.com";
    //   window.opener = null;
    //   window.open('', '_self', '');
      // window.close();
    // window.open("https://www.google.com","_self").close()    
//     setTimeout(function() {
// window.close();
// }, 5000); // 延迟5秒执行
// this.newWindow = window.open('', '_blank');
// console.log('this.newWindow',this.newWindow);
//           this.newWindow.document.write('<p>该窗口将被关闭</p>');
//           setTimeout(() => {
//             this.newWindow.close();
//           }, 2000); // 2秒后关闭新窗口
//         }

    
    }
  }

}
</script>

<style lang="scss" scoped>
.index{
  position: absolute;  
    left: 0;  
    top: 0;  
    width: 100vw;  
    height: 100vh;  
    object-fit: cover;  
}
</style>