<template>
  <div id="app" class="index" ref="appContainer" @contextmenu.prevent @mouseup="appContainerTouch"
    @touchend="appContainerTouch">
    <!--  v-if="isMobile" -->
    <!-- <div style="background: red;position: fixed;top:30%;left: 0;  z-index: 99999;font-size: 20px;">inputShow:{{ inputShow }},isIgnore:{{
    isIgnore }}</div> -->
    <div class="OvertimeUp" v-if="OvertimeShow" :class="isMobile?'OvertimeUp-iPhone':''">
      <div class="OvertimeUp-tips" :class="isMobile?'OvertimeUp-tips-iPhone':''">提示</div>
      <div class="OvertimeUp-text" v-if="isMobile">{{TipsText1}}</div>
      <div class="OvertimeUp-text" v-if="isMobile">{{ TipsText2 }}</div>
      <div class="OvertimeUp-text-PC" v-if="!isMobile">{{TipsText1}}</div>
      <div class="OvertimeUp-text-PC" v-if="!isMobile">{{ TipsText2 }}</div>
      <div class="OvertimeUp-buttom" @click="OvertimeShow = false" :class="isMobile?'OvertimeUp-buttom-iPhone':''">知道了</div>
    </div>
    <input v-if="isMobile" class="transparentInput" :class="isIgnore ? 'pointer' : ''" ref="input" type="text"
      v-model="inputValue" @focus="onTIFocus" @blur="onTIBlur">
    <!-- 观看模式昵称的输入框 -->
    <!-- <div v-if="inputNickName" class="modalInputPanel" v-bind:style="viewPortStyle">
      <div class="container">
        <div class="info">
          <div v-if="showNicknameInput" type="flex" align="middle" class="info-row">
            <span class="container-label">{{ ui.requireNickName }}：</span>
            <div class="inputContainer">
              <input :class="nicknameRequireClass" type="text" v-model.trim="nickname" v-on:focus="onInputFocus" />
            </div>
          </div>
          <div v-if="showAuthCodeInput" type="flex" align="middle" class="info-row">
            <span class="container-label">{{ ui.requireAuthCode }}：</span>
            <div class="inputContainer">
              <input :class="authCodeRequireClass" type="text" v-model.trim="authCode"
                v-on:focus="onAuthCodeInputFocus" />
            </div>
          </div>
        </div>
        <div class="buttonContainer">
          <Btn class="submit" :title="ui.buttonSubmit" submit="false" v-on:click="onSubmitNickName" />
        </div>
      </div>
    </div> -->
    <!-- 横竖屏提示 -->
    <div class="LandscapePrompt">
      <div class="rotation-tips">
        <div class="unpack">
          <div class="pic-rotate"></div>
          <div class="text">打开屏幕旋转</div>
        </div>
        <div class="unpack">
          <div class="pic-middle">
            <div class="pic-block"></div>
            <div class="pic-arrow"></div>
          </div>
        </div>
        <div class="unpack">
          <div class="pic-experience"></div>
          <div class="text">横屏开始体验</div>
        </div>
      </div>
    </div>

    <!-- 手机端 UI -->
    <MobileIndex v-if="cloudReady && isMobile"></MobileIndex>
    <!-- 通用 UI -->
    <!-- 警告框 -->
    <!-- <Alert /> -->
    <!-- 顶部提示框 -->
    <Notify />
    <!-- 浮动提示 -->
    <!-- <Toast v-if="cloudReady" /> -->
    <!-- 确认框 -->
    <!-- <Confirm /> -->



    <!-- 网络状态 -->
    <States />
    <!-- 输入框，用于云端输入 -->
    <!-- <Input v-if="cloudReady && !isMobile" /> -->
    <!-- 鸟瞰模式 -->
    <AerialView v-if="cloudReady && showAerialView" />
    <!-- 互动模式 -->
    <PlayerMode />
  </div>
</template>

<script>

import { LarkSR } from "larksr_websdk";
import MobileIndex from "@/components/mobile/index";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import Unit from '@/utils/unit';
import Capabilities from '@/utils/capabilities'
// import Alert from './components/alert/alert';
// import Btn from './components/button/button';
import Notify from '@/components/notify/notify';
// import Toast               from './components/toast/toast';
// import Confirm from './components/confirm/confirm';
import States from '@/components/states_modal/states_modal';
// import Input               from './components/input/input.vue'
import AerialView from '@/components/aerial_view/aerial_view.vue'
import PlayerMode from "@/components/player_mode/player_mode";
import Load from '@/load';
import store from "@/store";
import {getProfile,getUserNickname} from "@/api/login"
export default {
  name: "App",
  components: {
    MobileIndex,
    // Alert,
    // Btn,
    Notify,
    // Toast,
    // Confirm,
    States,
    // Input,
    AerialView,
    PlayerMode,
  },
  data() {
    return {
      email:"",//邮箱
  
      Nick:'',//姓名
      NickShow:false,//姓名判断
      emailShow:false,//邮箱判断
      AudioStatus: null,//声音true
      TipsText1: "",//弹框文字
      TipsText2: "",
      Overtime: true,//超时和连接关闭
      OvertimeShow: false,//超时和连接关闭弹窗
      isIgnore: true,
      LandscapeFirst: true,//只执行一次
      inputShow: false,//input状态
      PCShow: false,//设备判断
      Audio: false,//麦克风状态
      appContainer: null,
      cloudReady: false,
      mutePlay: false,
      inputValue: '',//隐藏的input，移动端调起系统键盘使用
      isIOS: Capabilities.os === 'iOS',
      inputFocusFlag: false, //云端输入事件input聚焦状态
      inputNickName: false,
      nicknameRequire: false,
      nickname: "",
      authCodeRequire: false,
      authCode: "",
      showAuthCodeInput: false, // 是否需要展示输入口令密码输入框
      showNicknameInput: false,
    };
  },
  watch: {
    inputValue: function (val) {
      if (val) {
        this.larksr.inputText(val);
        this.inputValue = '';
      }
    }
  },
  computed: {
    nicknameRequireClass() {
      return this.nicknameRequire ? "warning" : "";
    },
    authCodeRequireClass() {
      return this.authCodeRequire ? "warning" : "";
    },
    ...mapState({
      larksr: (state) => state.larksr,
      ui: (state) => state.ui,
      isMobile: (state) => state.isMobile,
      showAerialView: state => state.showAerialView,
      playerMode: (state) => state.playerMode,
    }),
    ...mapGetters({
      isObMode: "playerMode/isObMode",
      playerList: "playerMode/playerList",
      viewPortStyle: 'viewPortStyle'
    }),
    
  },
  methods: {
    // 请求后台接口
//    async getProfile(){
// const res= await getProfile()
//     },
    // 获取昵称
  async  getUserNickname(){
    const account= this.email?this.email:JSON.parse(localStorage.getItem('email'))
    try {  
        const res = await getUserNickname({ account: account});  
        this.Nick=res.data
    } catch (error) {  
        // 处理错误  
        console.error('获取昵称时发生错误:', error);  
        // 在这里可以根据需要执行其他错误处理逻辑  
       this.NickShow=true
       this.$emit('Loading-click','您无权限访问此系统'); // 触发自定义事件
this.getProfile()
    }  
    },
    // 开始连接
    async start() {
      const { larksr } = this;
      try {
        let params = {};
        // 要使用的云端资源的应用 ID
        // params.appliId = "1251199419211055104";//测试
         params.appliId = "1251206425451954176";//生产
        if (!params.appliId) {
          this.alert({ des: "请设置appliId参数" })
            .then(() => {
              Unit.quit();
            });
          return;
        }

        // groups
        if (Load.endDate) {
          params.endDate = Load.endDate;
        }
        if (Load.clientMac) {
          params.clientMac = Load.clientMac;
        }
        if (Load.groupId) {
          params.groupId = Load.groupId;
        }
        if (Load.regionId) {
          params.regionId = Load.regionId;
        }

        // 互动模式
        if (Load.taskid) {
          params.taskId = Load.taskid;
        }

        //启动模式：0：普通模式, 1：互动模式（一人操作多人观看），2: 多人协同（键盘鼠标放开，需要应用配合）
        // 互动模式测试连接 
        // 所有者 userType = 1
        // http://localhost:8081/?appliId=925773094113509376&playerMode=1&userType=1&authCode=fMFu92vg
        // 观看者 userType = 0
        // http://localhost:8081/?appliId=925773094113509376&playerMode=1&userType=0&authCode=fMFu92vg
        if (Load.playerMode) {
          params.playerMode = Load.playerMode;
        }

        //Task所有者:1 /  观察者:0
        if (Load.userType || Load.userType === 0) {
          params.userType = Load.userType;
        }

        // 用户手动输入昵称
        if (this.nickname) {
          larksr.params.nickname = this.nickname;
        }
        if (this.nickname) {
          params.nickname = this.nickname;
        }
        // url 载入昵称
        if (Load.nickname) {
          params.nickname = Load.nickname;
        }

        //口令:8位唯一码,写入TaskInfo
        if (Load.authCode) {
          params.authCode = Load.authCode;
        }
        if (Load.accessType && Load.accessType === '0') {
          // 私有应用需要传参 accessType shareId authCode（原身roomCode）
          params.authCode = this.authCode;
          params.accessType = '0';
          params.shareId = Load.shareId;
          larksr.params.accessType = Load.accessType;
        }

        //指定节点分配
        if (Load.targetServerIp) {
          params.targetServerIp = Load.targetServerIp;
        }

        // keys
        if (Load.appKey) {
          params.appKey = Load.appKey;
        }
        if (Load.timeStamp) {
          params.timestamp = Load.timeStamp;
        }
        if (Load.signature) {
          params.signature = Load.signature;
        }
        if (Load.language) {
          params.language = Load.language;
        } else {
          params.language = this.navigatorLan;
        }

        if (Object.keys(Load.extraparams).length > 0) {
          params = Object.assign(params, Load.extraparams)
        }

        await larksr.connect(params);

        console.log("connect", params);

        // 设置网页 title
        if (larksr.params.appliName) {
          document.title = decodeURI(decodeURI(larksr.params.appliName));
        }
      } catch (e) {
        console.error('connect and int failed.', e);
        this.alert({ des: e.message ? e.message : JSON.stringify(e) })
          .then(() => {
            Unit.quit();
          });
      }
    },
    onTIFocus() {
      // 第一次聚焦，focus状态
      // if (this.inputShow == false) {
      //   // alert("inputShow false");
      //   this.inputShow = true;

      //   // //打开穿透，后续focus失效
      // if(this.inputShow){
      //   this.inputShow=false

      // }else{
      // setTimeout(()=>{
      //   this.isIgnore=true;
      // },100)

      // this.inputShow=true
      // }
      // }

      // // 第二次点击，focus状态，穿透状态，不会继续focus
      // else {
      //   // alert("inputShow true");

      //   this.inputShow = false
      // }

      // this.isIgnore = true;
    },
    onTIBlur() {
      // this.$refs.input.style.display = "none";
      this.isIgnore = true;
    },
    // onInputFocus() {
    //   console.log("on focus");
    //   this.nicknameRequire = false;
    // },
    // onAuthCodeInputFocus() {
    //   console.log("on authCode input focus");
    //   this.authCodeRequire = false;
    // },
    // onSubmitNickName() {
    //   console.log("on submit nickname", this.nickname);
    //   console.log('on submit authCode', this.authCode);
    //   if (this.showAuthCodeInput) {// 只有私有应用 会出现输入口令密码的情况
    //     if (this.showNicknameInput && this.nickname == "") {
    //       this.nicknameRequire = true;
    //     } else if (this.showAuthCodeInput && this.authCode == "") {
    //       this.authCodeRequire = true;
    //     } else {
    //       this.inputNickName = false;
    //       this.setNickName(this.nickname);
    //       this.showNicknameInput = false;
    //       this.showAuthCodeInput = false;
    //       this.start(this.taskStated);
    //     }

    //   } else {
    //     if (this.nickname != "") {
    //       // 填写昵称后再开始
    //       this.inputNickName = false;
    //       this.setNickName(this.nickname);
    //       this.showNicknameInput = false;
    //       this.start(this.taskStated);
    //     } else {
    //       this.nicknameRequire = true;
    //     }
    //   }
    // },
    appContainerTouch() {
      this.onMutePlay();
      if (this.isMobile && this.inputFocusFlag) {
        this.$refs.input.focus();
      }
    },
    onMutePlay() {
      if (!this.mutePlay) {
        return;
      }
      this.larksr.videoComponent.sountPlayout();
      this.larksr.videoComponent.playVideo();
      this.mutePlay = false;
    },
    sysKeybaordEnterOrBackspace(e) {
      if (this.isMobile && this.inputFocusFlag) {
        if (e.key === 'Enter') {
          this.larksr.inputText(this.inputValue);
          this.inputValue = '';
          this.$refs.input.blur();
        } else if (e.key === 'Backspace') {
          this.larksr.keyDown('Backspace', false);
          setTimeout(() => {
            this.larksr.keyUp('Backspace');
          }, 50)
        }
      }
    },
    ...mapMutations({
      setLarksr: "setLarksr",
      setAggregatedStats: "setAggregatedStats",
      setNickName: "playerMode/setNickName",
      setShareUrl: "playerMode/setShareUrl",
    }),
    ...mapActions({
      "resize": "resize",
      'toast': 'toast/toast',
      'notify': 'notifyBar/notify',
      'alert': 'modalAlert/showModalAlert',
      'confirm': 'modalConfirm/showModalConfirm',
      'resetLocalization': 'resetLocalization',
    }),
  },
  mounted() {
    let that = this;
  
  that.email =window.location.href.split('?')[1]?window.location.href.split('?')[1].split('=')[1]:''; // 使用问号分割字符串  
        const getItemEmail = localStorage.getItem('email') ? JSON.parse(localStorage.getItem('email')) : "";

        if(  !that.email && !getItemEmail ){
that.$emit('Loading-click','未检测到邮箱账号'); // 触发自定义事件
// that.getProfile()
this.emailShow=true
        }else{
          if(that.email){
            localStorage.setItem('email',JSON.stringify(that.email))
          }else{
            that.email=JSON.parse(localStorage.getItem('email'))
          }
that.getUserNickname()
    
      // 移除敏感信息的函数
      document.addEventListener('DOMContentLoaded', function() {
  if (that.email) {
      // 获取当前 URL
  let url = new URL(window.location.href);
    let newUrl = url.origin + url.pathname;
    // 使用 history.replaceState 替换当前 URL
    history.replaceState(null, document.title, newUrl);
  }
});
        }
  


    const rotationTips = document.querySelector(".LandscapePrompt");//横屏提示
    // 判断手机还是电脑
    function isPC() {
      const specialIPad = /Macintosh/i.test(navigator.userAgent) && (typeof navigator.maxTouchPoints === 'number' && navigator.maxTouchPoints > 0);
      return !specialIPad && !(/Android|Alicar|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent));
    }
    // 判断设备
    (function EquipmentJudgment() {

      if (isPC()) {
        // pc设备，执行相应逻辑
        if (that.LandscapeFirst) {
          // that.initpxygame();
          that.PCShow = true


          // 设置麦克风权限
          // navigator.mediaDevices.getUserMedia({ audio: true })
          //   .then((res) => {
          //     // 允许麦克风权限
          //     console.log('允许麦克风');
          //   })
          //   .catch((error) => {
          //     // 拒绝麦克风权限
          //     console.log('拒绝麦克风');
          //   })

          // that.client.sendTextToDataChannel(obj);
          that.LandscapeFirst = false
        }
      } else {
        // 判断是否为ios设备
        if (that.isIOS) {
          // alert('苹果设备')
        } else {
          that.$refs.input.style.display = "none";
        }

        // 手机 设备，执行相应逻辑
        // 页面加载时检测一次屏幕方向
        checkOrientation();
        // 监听窗口大小变化事件，实时更新屏幕方向
        window.addEventListener("resize", checkOrientation);
        that.PCShow = false
      }
    })();

    // 检测当前屏幕方向
    function checkOrientation() {
      if (window.innerWidth > window.innerHeight) {
        // 横屏状态
        rotationTips.style.display = "none";
        if (that.LandscapeFirst) {
          // that.initpxygame();

          that.LandscapeFirst = false
        }
      }

      else {
        // 竖屏状态
        rotationTips.style.display = "block";
      }
    }




    // 从 url 载入参数
    Load.InitProcess();
    // init player mode params
    store.dispatch('playerMode/initFromLoader');
    if (this.isMobile) {
      this.$refs.input.addEventListener('keyup', (e) => {
        this.sysKeybaordEnterOrBackspace(e);
      })
    }

    // 直接调用进入应用接口创建实例，自动配置连接云端资源
    const larksr = new LarkSR({
      // doc https://pingxingyun.github.io/webclient_sdk/config.html
      rootElement: this.$refs["appContainer"],
      // 服务器地址,实际使用中填写您的服务器地址
      // 如：http://222.128.6.137:8181/
      // serverAddress: "http://222.128.6.137:8181/",
      serverAddress: "https://wt.molion.tech",//测试
      // serverAddress: "https://wt.molion.tech/",//生产
      // 视频缩放模式，默认保留宽高比，不会拉伸并完整显示在容器中
      // scaleMode: "contain",
      // 0 -》 用户手动触发, 1 -》 首次点击进入触发, 2 -》 每次点击触发
      // fullScreenMode: 0,
      // mobileFullScreenMode: 1,
      // 测试载入背景图
      // loadingBgUrl: 'https://vpt.ali.bmwcn.cloud/character/loadingbg.jpg',
      // show log
      // logLevel: 'warn',
      audioInputAutoStart: true,
      initResolutionType: 0,
      codeRate: 3500,//码率
      frameRate: 30,//帧率
      // 单独上传流程要求渲染服务器版本大于3290）
      useSeparateMediaSharePeer: true,
      onlyHandleRootElementTransform: false,//强制横屏模式
      mobileForceLandscape: false//手机端是否强制横屏
    });

    // 初始化您的授权ID
    // "SDK 授权码，联系 business@pingxingyun.com 获取,注意是 SDK 本身的授权码，不是服务器上的授权"
    // 使用 Unit.queryString("sdkID") 可从 url 载入名称为 sdkID 的参数
    larksr.initSDKAuthCode("5d28f1081c2842d698acc391df1aa223")
      .then(() => {
        larksr.sendTextToDataChannel('999');


        // 连接平行云托管平台的应用
        // client.connectWithPxyHost({
        //     // 从平行云托管平台获取
        //     appliId: "924970933473509376",
        //     // playerMode: 2,
        //     // userType: 1,
        //     // taskId: "",
        //     // nickname: "Test",
        // })
        // .catch(function(e) {
        //     console.error('enter appli falied', e);
        //     alert(JSON.stringify(e));
        // });

        /**
         * 
         // 固定传入调用参数
         // 从 url 中获取参数并传入参考上面 start 方法
  
        // start connect;
        larksr.connect({
          // 要使用的云端资源的应用 ID，从后云雀后台接口获取
          // 参考查询应用一栏文档
          // https://www.pingxingyun.com/online/api3_2.html?id=476
          // 如 222.128.6.137:8181 系统下的 879414254636105728 应用
          appliId: "913050644103823360",
  
          // 其他可选参数如下
          // 互动模式
          //启动模式：0：普通模式, 1：互动模式（一人操作多人观看），2: 多人协同（键盘鼠标放开，需要应用配合）
          playerMode: Load.playerMode,
          //Task所有者:1 /  观察者:0
          userType: Load.userType,
          //口令:8位唯一码,写入TaskInfo
          // 新版服务器接口 从roomCode改为authCode
          authCode: Load.authCode,
          taskId: Load.taskid,
  
          // groups
          // clientMac: '',
          // groupId: '',
          // regionId: '',
          //指定节点分配
          // targetServerIp: '',
          // keys
          // appKey: '',
          // timestamp: '',
          // signature: '',
          // "extraParam.test1": "test1", // extrams
          // "extraParam.test2": "test2", // extrams
          // "extraParam.test3": "test3", // extrams
        })
        .then(() => {
          console.log('enter success');
        })
        .catch((e) => {
          console.error(e);
          alert(JSON.stringify(e));
        }); 
  
        */
      })
      .catch((e) => {
        console.error(e);
        alert(JSON.stringify(e));
      });

    // 音频设备相关
    // 该功能匹配的服务端版本最低为 V3.2.51
    // 客户端打开后云端应用可直接通过读取声卡上的麦克风接收到音频。
    // 使用该功能要注意在后台开启智能语音功能
    // 打开音频设备，可选传入 deviceId,
    // client.openAudio();
    // 设置已打开的音频track状态
    // client.setAudioEnable(true);
    // 关闭已打开的音频流
    // client.closeAudio();
    // 返回可用的音频设备
    // client.getConnectedAudioinputDevices();


    // ...
    // 主动关闭并清理资源
    // client.close();

    //数据通道打开
    larksr.on("larkevent", function (e) {
      // console.log('数据通道', e.data);
      // 传递设备
      if (e.data == '1000') {
        console.log('传递设备型号1000');
        let obj =
        {
          code: 1001,
          PC: that.PCShow,
        }
        let obj2 =
        {
          code: 1002,
          Email:that.email,
          Nick:that.Nick
        }
        obj = JSON.stringify(obj)
        console.log('JSONPC', obj);
        obj2 = JSON.stringify(obj2)
        console.log('JSONPC', obj2);
        larksr.sendTextToDataChannel(obj);
        larksr.sendTextToDataChannel(obj2);
        localStorage.removeItem('email');

      }

      // 弹起本地键盘
      if (e.data == '1002') {
        console.log('弹键盘');
        // console.log(' that.$refs.input', that.$refs.input);
        that.isIgnore = false;
        // that.$refs.input.style.display = "block";

        // that.isIgnore=false
        // this.inputShow=true
        // var myintervalInput = setTimeout(() => {
        //   console.log("这个信息会在10秒后显示");
        //   that.$refs.input.style.display = "none";
        //   // 触发点击事件
        //   clearInterval(myintervalInput);//清除定时器
        // },
        //   10000)
      }
      if (e.data == '1003') {
        console.log('blur++++');
      }
      if (e.data == '1004') {
        console.log("请求开启麦克风权限1004");


        // 查询麦克风权限
        navigator.permissions.query({ name: 'microphone' })
          .then((res) => {
            if (res.state === 'granted') {
              // 已授权
              console.log('已授权');
              that.AudioStatus = true
            } else {
              console.log('未授权');
              that.AudioStatus = false
              that.OvertimeShow = true
              that.TipsText1 = '未检测到麦克风'
              that.TipsText2 = '请打开浏览器相关权限后，刷新页面'
            }

          })
        // var myintervalInput = setInterval(() => {
        //       console.log("这个信息会在1秒后显示");
        //   let AudioObj =
        // {
        //   code: 1005,
        //   Audio:0
        // }
        // AudioObj = JSON.stringify(AudioObj)
        // console.log('JSONAudio', AudioObj);
        // larksr.sendTextToDataChannel(AudioObj);
        // larksr.sendTextToDataChannel("6666666666");
        // console.log('6666');
        // 触发点击事件
        // clearInterval(myintervalInput);//清除定时器
        // },
        //   1000)

      }
      if (e.data == '1006') {
        console.log('1006关闭loading');
        if(that.emailShow || that.NickShow){
          console.log('没有邮箱或者nike账号不对');
        }else{
          that.$emit('LarkSR-click'); // 触发自定义事件

        }
      }
    });



    // 监听连接成功事件
    larksr.on("connect", (e) => {
      console.log("LarkSRClientEvent CONNECT", e);

      // 可能从getstartappinfo之后才获取来的参数.
      // 后台配置的是否显示玩家列表参数
      store.commit("playerMode/setShowPlayerList", larksr.params.showPlayerList);
    });

    larksr.on("gotremotesteam", (e) => {
      console.log("LarkSRClientEvent gotremotesteam", e);
    });

    larksr.on("meidaloaded", (e) => {
      console.log("LarkSRClientEvent meidaloaded", e);
      this.cloudReady = true;
    });

    larksr.on("mediaplaysuccess", (e) => {
      console.log("LarkSRClientEvent mediaplaysuccess", e);
    });

    // 3.2.329 使用 larksdk 中的播放按钮
    // larksr.on("mediaplayfailed", (e) => {
    //   console.log("LarkSRClientEvent mediaplayfailed", e);
    //   this.alert({des: "开始"})
    //   .then(() => {
    //       larksr.videoComponent.sountPlayout();
    //       larksr.videoComponent.playVideo();
    //   });
    // });

    larksr.on("meidaplaymute", (e) => {
      console.log("LarkSRClientEvent meidaplaymute", e);
      this.mutePlay = true;
      this.toast({ text: '点击屏幕中心打开音频', position: 2, level: 3 });
    });

    larksr.on("peerstatusreport", (e) => {
      // console.log("LarkSRClientEvent peerstatusreport", e);
      this.setAggregatedStats(e.data);
    });

    larksr.on('error', (e) => {
      console.error("LarkSRClientEvent error", e.message);
      this.alert({ des: e.message, code: e.code })
        .then(() => {
          Unit.quit();
        });
    });

    larksr.on('info', (e) => {
      console.log("LarkSRClientEvent info", e);
      this.toast({ text: e.message });
    });
    console.log("load appli success", larksr);

    larksr.on('resourcenotenough', (e) => {
      console.log("LarkSRClientEvent resourcenotenough", e);
      if (e.type === 0) {
        alert("当前系统繁忙，请稍后再试！");
      }
    });
    // 连接超时
    larksr.on('operatetimeout', (e) => {
      console.log("operatetimeout11111111111111111", e);
      // 执行我想要的操作
      that.Overtime = false
      that.OvertimeShow = true
      that.TipsText1 = '由于您长时间未操作，连接已断开'
      that.TipsText2 = '请刷新页面重试，或联系管理员'
    });
    // 连接错误
    larksr.on('error', (e) => {
      console.log("error", e);
      if (that.Overtime) {
        // 执行我想要的操作
        that.OvertimeShow = true
        that.TipsText1 = '连接云渲染服务器失败'
        that.TipsText2 = '请刷新页面重试，或联系管理员'
        // alert('连接失败弹出')
      }

    });

    // 云端Input输入事件
    larksr.on('apprequestinput', (e) => {
      console.log('apprequestinput', e)
      if (e.data === true) {
        this.inputFocusFlag = true;
        if (this.isMobile) {
          //打开系统键盘
          if (e.data === true) {
            this.$refs.input.focus();
            if (this.larksr) this.larksr.op.setKeyboardEnable(false);
          }
        }
      } else {
        this.inputFocusFlag = false;
        if (this.isMobile) {
          this.$refs.input.blur();
          if (this.larksr) this.larksr.op.setKeyboardEnable(true);
        }
      }
    });

    larksr.on('playerlist', (e) => {
      console.log('PLAYER_LIST', e.data);
      store.dispatch('playerMode/updatePlayerList', e.data);
    });


    // reset states.
    this.setLarksr(larksr);
    this.resetLocalization();
    this.resize();

    if (Load.accessType === '0' && Load.shareId) { // 私有应用
      if (Load.authCode) { //有口令密码
        this.authCode = Load.authCode;
        this.showAuthCodeInput = false;

        // 互动模式需要填昵称
        if (this.playerMode.playerModeType != 0 && this.playerMode.nickName == "") {
          // 填写昵称后再开始
          this.showNicknameInput = true;
          this.inputNickName = true;
        } else {
          // start connect.
          this.start();
        }
      } else {
        // 需要用户输入口令密码
        this.showAuthCodeInput = true;
        // 互动模式需要填昵称
        if (this.playerMode.playerModeType != 0 && this.playerMode.nickName == "") {
          this.showNicknameInput = true;
        }
        this.inputNickName = true;
      }
    } else { // 公有应用
      // 互动模式需要填昵称
      if (this.playerMode.playerModeType != 0 && this.playerMode.nickName == "") {
        console.log("need nick name");
        // 填写昵称后再开始
        this.inputNickName = true;
        this.showNicknameInput = true;
      } else {
        // start connect.
        this.start();
      }
    }


    // this.alert({des: 1});
    // this.confirm({des:"22"});
    console.log("ref", this.$refs["appContainer"]);

    let resizeTimeout = null;
    window.addEventListener("resize", () => {
      if (resizeTimeout) {
        window.clearTimeout(resizeTimeout);
      }
      resizeTimeout = window.setTimeout(() => {
        this.resize();
        resizeTimeout = null;
      }, 200);
    });
    window.addEventListener("orientationchange", () => {
      if (resizeTimeout) {
        window.clearTimeout(resizeTimeout);
      }
      resizeTimeout = window.setTimeout(() => {
        this.resize();
        resizeTimeout = null;
      }, 200);
    });
    this.resize();
  },
  beforeUnmount() {
    // 主动关闭
    this.larksr?.close();
  },
  beforeDestroy() {
    if (this.isMobile) {
      this.$refs.input.removeEventListener('keyup', (e) => {
        this.sysKeybaordEnterOrBackspace(e);
      })
    }
  }
};
</script>

<style lang="scss" scoped>
@import "./LarkSR.scss";

.pointer {
  pointer-events: none;
}

.transparentInput {
  border: none;
  background: transparent;
  color: transparent;
}

.transparentInput:focus {
  border: none;
  outline: none;
  color: transparent;
  caret-color: transparent;
}
</style>
