import Vue from 'vue'
import App from './App.vue'
import store from '@/store';
// import router from './router/router-index.js'
// import VueRouter from 'vue-router';
Vue.config.productionTip = false
// Vue.use(VueRouter);
new Vue({
  mixins: [],
  render: h => h(App),
  store,
  // router
}).$mount('#app')
