<template>
    <div class="body">
      <div class="login">
        <div class="logo"></div>
     <div class="text">加载中，请稍后...</div>
  
    </div>
    </div>
  </template>
  
  <script>
  import {demoLogin} from "../api/login"
  import { mapState} from "vuex";
  export default {
  
   
    data() {
      return {
      
      }
    },

}
  </script>
  
  <style lang="scss" scoped>
  .body {
    width: 100vw;
    height: 100vh;
    background-image: url(../assets/imgBg/LogInImg.png);
    background-size: 100% 100%;
    overflow: hidden;
    margin: 0;
    padding: 0;
 
    .login {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 733px;
      height: 550px;
      background: rgb(255, 255, 255, 0.75);
      border-radius: 30px;
      border: 2px solid #fff;
      padding: 20px 132.5px;
      box-sizing: border-box;
      .logo{
    position: absolute;
    top: 45%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 282px;
      height: 145px;
      background-image: url(../assets/imgBg/takeda.png);
      background-size: 100% 100%;
  }
    
  .text{
    position: absolute;
    top: 63%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 32px;
      font-weight: 600;
  }

  

  

  
    }
  }
  </style>